<template>
  <div class="gray-background"></div>
  <TitleBar />
  <div class="main-container">
    <div class="login-container">
      <img class="login-logo" src="@/assets/algeomath_logo.png" width="200px" />
      <div class="text-container">
        <p>ID</p>
        <input type="text" placeholder="아이디" v-model="user.username" maxlength="20" autofocus />
      </div>
      <div class="text-container">
        <p>Password</p>
        <input type="password" placeholder="비밀번호 " id="password" v-model="user.password" maxlength="16"
          @keyup.enter="Login()" />
      </div>
      <div class="line"></div>
      <button @click="Login" type="button" class="loginbtn">
        로그인
      </button>
      <router-link to="/SignupPage">
        <button type="button" class="signupbtn">
          회원가입
        </button>
      </router-link>
      <ul class="goPage">
        <li><a href="https://logins.kofac.re.kr/terms/00/privacyTerms.do">개인정보처리방침 </a></li>
        <li><a href="https://www.algeomath.kr/algeo/contents/join_term/1/view.do">이용약관 </a></li>
      </ul>
    </div>
    <img class="floating-turtle" src="@/assets/mr_turtle.png" alt="Floating Turtle" />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import TitleBar from '@/components/MainTitleBar.vue';
import axios from 'axios';
//import { v1 as uuidv1 } from "uuid";
export default {
  name: 'LoginPage',
  components: {
    TitleBar,
  },
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    }
  },
  props: {
    msg: String
  },
  methods: {
    Login: function () {

      if (this.user.username === '') { alert('아이디를 입력해주세요.'); return; }
      if (this.user.password === '') { alert('비밀번호를 입력해주세요.'); return; }
      axios.post('https://api.aialab.co.kr/users/login', this.user,
        { withCredentials: true }
      )
        .then(res => {
          if (res.status === 200) {
            alert('로그인 성공!');

            if (this.user.username === 'Teacherusername') {
              Cookies.set('login_cookie', res.data.access_token);//실사용시 제거
              Cookies.set('login_refresh_cookie', res.data.refresh_token);//실사용시 제거
              //Cookies.set('lesson_cookie', uuidv1());
              this.$router.push('/Teacher');
            }
            else {
              Cookies.set('login_cookie', res.data.access_token);//실사용시 제거
              Cookies.set('login_refresh_cookie', res.data.refresh_token);//실사용시 제거
              //Cookies.set('lesson_cookie', uuidv1());
              // to be replaced with the actual path (e.g., implemented subject page)
              this.$router.push('/CourseSelectionPage');
              // this.$router.push('/Subjects'); // if implemented
            }
          } else {
            alert('비밀번호 또는 아이디가 일치하지 않습니다.');
          }
        })
        .catch(error => {
          console.error('Error during signup:', error);
          alert('비밀번호 또는 아이디가 일치하지 않습니다.');
        });
    },
  },
}
</script>
<style scoped>
.gray-background {
  position: fixed;
  z-index: 1;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #C7D3E5;
}

.main-container {
  display: flex;
  width: 100%;
  height: 98vh;
  margin: auto;
  font-family: 'NanumSquare';
}

.login-container {
  z-index: 2;
  margin: auto;
  padding: 25px 50px;
  width: 450px;
  align-items: center;
  border-radius: 5%;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.login-logo {
  margin-bottom: 60px;
}

.text-container {
  margin-bottom: 30px;
}

.text-container input[type=text],
.text-container input[type=password] {
  width: 337px;
  height: 36px;
  border-radius: 20px;
  padding: 17px;
  border: 2px solid #cccccc;
  background-color: #ffffff;
  font-size: 12pt;
  box-sizing: border-box;
  font-family: 'NanumSquare';
}

.text-container>p {
  margin: 0 0 5px 0;
}

.text-container>input {
  padding: 0 20px;
}

.line {
  border-top: 1px solid #d8d8d8;
  margin: 20px auto;
}

.loginbtn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 350px;
  height: 54px;
  border: 0;
  border-radius: 30px;
  background-color: #5C7FB4;
  font-size: 14pt;
  font-weight: 1000;
  color: #fff;
  cursor: pointer;
  font-family: 'NanumSquare';
}

.signupbtn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 54px;
  border: 0;
  border-radius: 30px;
  background-color: #c2c2c2;
  font-size: 14pt;
  font-weight: 1000;
  color: #fff;
  cursor: pointer;
  font-family: 'NanumSquare';
}

.goPage {
  display: flex;
  padding: 0px;
}

.goPage li:first-child {
  position: relative;
  margin-right: 25px;
}

.goPage li:first-child:after {
  content: '';
  position: absolute;
  right: -15px;
  top: calc(50% - 8px);
  display: block;
  width: 1px;
  height: 16px;
  background: #a3a3a3;
}

.goPage li a {
  font-size: 14px;
  color: #9e917d;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.floating-turtle {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: auto;
  z-index: 1000;
  /* Make sure it appears on top */
  animation: float 6s ease-in-out infinite;
}

.floating-turtle:hover {
  content: url('@/assets/mr_turtle_on.png');
}

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
</style>
