<template>
    <div class="course-selection-page">
        <div class="gray-background"></div>
        <TitleBar :lessonPhasesIndex="0"/>
        <div class="main-container">
            <div class="course-selection-container">
                <div class="course-box" @click="goToSubject('Geometry')">
                    <img src="@/assets/geometry_image.png" alt="Geometry Icon" class="course-icon" />
                    <p>기하</p>
                </div>
                <div class="course-box" @click="goToSubject('Algebra')">
                    <img src="@/assets/algebra_image.jpg" alt="Algebra Icon" class="course-icon" />
                    <p>대수</p>
                </div>
                <div class="course-box" @click="goToSubject('Function')">
                    <img src="@/assets/function_image.png" alt="Functions Icon" class="course-icon" />
                    <p>함수</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/MainTitleBar.vue';
import store from "@/store";

export default {
    name: 'CourseSelectionPage',
    components: {
        TitleBar,
    },
    methods: {
        goToSubject(subject) {
            this.$router.push(`/${subject}/${subject}Question1`);
        },
    },
    created() {
        // lastPath 가져오기
        const lastPath = store.state.lastPath;

        // lastPath가 존재하고 "end"로 끝나지 않으면 메시지 표시
        if (lastPath && !lastPath.endsWith("end")) {
            const proceed = window.confirm(
                "이전에 마지막 페이지까지 완료되지 않았습니다. 이전 페이지로 이동하시겠습니까?"
            );

            // 사용자가 확인을 누르면 lastPath로 이동
            if (proceed) {
                this.$router.push(lastPath);
            }
        }
    },
};
</script>

<style scoped>
.course-selection-page {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.gray-background {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #C7D3E5;
}

.main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    z-index: 2;
    position: relative;
}

.course-selection-container {
    display: flex;
    justify-content: space-around;
    width: 80%;
    max-width: 1000px;
}

.course-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 10px;
    background-color: #ffffff;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.course-box:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.course-icon {
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
}

.course-box p {
    font-size: 20px;
    font-weight: bold;
    color: #4A4A4A;
    margin: 0;
}
</style>
