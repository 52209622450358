import Cookies from "js-cookie";
import axios from "axios";
//Todo : dev 와 prod 환경에 따라서 다르게 설정해야함
export const BASE_URL = 'https://api.aialab.co.kr';

const getTokens = async () => {
    const accessToken =   await Cookies.get('login_cookie');
    const refreshToken =  await Cookies.get('login_refresh_cookie');

    return { accessToken, refreshToken };
}

const setInterceptors = (instance) => {
    instance.interceptors.request.use(
        async (config) => {
            const { accessToken, refreshToken } = await getTokens();
            if (accessToken) {
                config.headers.Authorization = `Bearer ${refreshToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const { refreshToken } = await getTokens();
                if (refreshToken) {
                    return axios
                        .post(`${BASE_URL}/auth/refresh`, {
                            refreshToken,
                        })
                        .then((res) => {
                            if (res.status === 201) {
                                Cookies.set('login_cookie', res.data.accessToken);
                                return instance(originalRequest);
                            }
                        });
                }
            }
            return Promise.reject(error);
        },
    );



    return instance;
};
const axiosInstance = setInterceptors(
    axios.create({
        baseURL: BASE_URL,
        withCredentials: true,
    }),
);



export default axiosInstance;
