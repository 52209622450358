import { createStore } from 'vuex';
import Cookies from "js-cookie";

const store = createStore({
  state: {
    algebraQuestion4Algeo: null,
    lessonCookie: null,
    lastPath:"",
  },
  mutations: {
    setAlgebraQuestion4Algeo(state, newAlgeo) {
      state.algebraQuestion4Algeo = newAlgeo;
    },
    setLessonCookie(state, lessonCookie) {
      state.lessonCookie = lessonCookie;
      Cookies.set('lesson_cookie', lessonCookie);
    },
    setLastPath(state, lastPath) {
      state.lastPath = lastPath;
    },
  },
  actions: {
    initializeLessonCookie({ commit, state }) {
      console.log(state.lessonCookie)
      const savedState = JSON.parse(localStorage.getItem('vuex')) || {};
      if (savedState.lessonCookie) {
        commit('setLessonCookie', savedState.lessonCookie);
      }
    },
  },
  plugins: [
    store => {
      store.subscribe((mutation, state) => {
        // 현재 로컬스토리지 상태 불러오기
        const savedState = JSON.parse(localStorage.getItem('vuex')) || {};
        
        // 기존 로컬스토리지 데이터와 현재 상태를 병합하여 저장
        const mergedState = { ...savedState, ...state };
        localStorage.setItem('vuex', JSON.stringify(mergedState));
      });
    },
  ],
});

const savedState = localStorage.getItem('vuex');
if (savedState) {
  store.replaceState(Object.assign(store.state, JSON.parse(savedState)));
}

export default store;