<template>
    <div class="progress-bar" v-if="shouldRender">
        <div v-for="step in totalSteps" :key="step" class="progress-item">
            <div class="step"
                :class="{ completed: step < currentStep, active: step === currentStep }">
                <span v-if="step !== totalSteps">{{ step }}</span>
                <img v-else :src="flagImage" :alt="`Step ${step} Flag`" class="flag" />
            </div>
            <!-- 가로바 추가, 마지막 스텝 이후에는 추가되지 않도록 조건 설정 -->
            <div v-if="step < totalSteps" 
                :class="['bar', { completed: step < currentStep }]"></div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";

export default {
    name: "ProgressBar",
    props: {
        totalSteps: {
            type: Number,
            required: true,
        },
        currentStep: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const shouldRender = computed(() => {
            return props.totalSteps && props.currentStep;
        });

        const flagImage = computed(() => {
            return props.currentStep === props.totalSteps
                ? require('@/assets/flag_green.png')
                : require('@/assets/flag_yellow.png');
        });

        return { shouldRender, flagImage };
    },
};
</script>

<style scoped>
.progress-bar {
    display: flex;
    align-items: center;
}

.progress-item {
    display: flex;
    align-items: center;
}

.step {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-right: 1px;
    display: inline-block;
    border: 1.5px solid transparent;
    background-color: white;
    color: green;
    font-size: 12px;
}

.completed {
    background-color: green;
    color: white;
    padding: 1px;
}

.active {
    border-color: green;
    padding: 1px;
}

.bar {
    width: 10px; /* 가로바 길이 */
    height: 2px; /* 가로바 두께 */
    background-color: lightgray; /* 기본 회색 */
    margin-right: 2px; /* 가로바 간격 */
}

.bar.completed {
    background-color: green; /* 현재 진행된 부분을 초록색으로 변경 */
}

.flag {
    width: 20px;
    height: 20px;
    display: block;
}
</style>
