import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store'; // Vuex store import
import MathJaxPlugin from './mathjax';

// Vue.config.warnHandler = function (msg, vm, trace) {
//     if (msg.includes('Component name')) {
//       // 다중 단어 경고 메시지를 무시
//       return;
//     }
//     console.warn(msg + trace);
//   };

const app = createApp(App);

// MathJax 라이브러리 스크립트 추가
const script = document.createElement('script');
script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
script.async = true;
document.head.appendChild(script);

app.use(MathJaxPlugin);

// Provide the store to all components
app.use(store);
store.dispatch('initializeLessonCookie');

app.use(router).mount('#app');
export const LOADING_STATUS = 'LOADING_STATUS'