import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "@/pages/LoginPage.vue";
import SignupPage from "@/pages/SignupPage.vue";
import EndPage from "@/pages/geometry/EndPage.vue";
import FunctionEndPage from "@/pages/function/EndPage.vue";
import CourseSelectionPage from "@/pages/CourseSelectionPage.vue";

const routes = [
    {
        path: "/",
        redirect: "/LoginPage",
    },
    {
        path: "/LoginPage",
        name: "LoginPage",
        component: LoginPage,
    },
    {
        path: "/SignupPage",
        name: "SignupPage",
        component: SignupPage,
    },
    ...createQuestionRoutes("Geometry", 1, 7), // 7 questions in Geometry,
    // temporarily added 1 question in Algebra and 1 question in Function
    ...createQuestionRoutes("Algebra", 1, 7), // 1 questions in Algebra,
    ...createQuestionRoutes("Function", 1, 11), // 1 questions in Function,
    {
        path: "/Geometry/end",
        name: "EndPage",
        component: EndPage,
    },
    {
        path: "/Function/end",
        name: "EndPage",
        component: FunctionEndPage,
    },
    {
        path: "/CourseSelectionPage", //과목 선택 페이지
        name: "CourseSelectionPage",
        component: CourseSelectionPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

function createQuestionRoutes(questionCategory, start, numberOfQuestions) {
    const routes = [];
    for (let i = start; i < start + numberOfQuestions; i++) {
        routes.push({
            path: `/${questionCategory}/${questionCategory}Question${i}`,
            name: `${questionCategory}-${i}`,
            component: () =>
                import(`@/pages/${questionCategory.toLowerCase()}/${questionCategory}Question${i}.vue`),
        });
    }
    return [...routes , {
        path : `/${questionCategory}/end`,
        name : `${questionCategory}-end`,
        component : () => import(`@/pages/${questionCategory.toLowerCase()}/EndPage.vue`)
    }];
}

export default router;
