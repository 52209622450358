// src/mathjax.js
export default {
    install: (app) => {
        // MathJax 설정
        window.MathJax = {
            tex: {
                inlineMath: [['$', '$'], ['\\(', '\\)']],
                displayMath: [['$$', '$$'], ['\\[', '\\]']]
            },
            svg: {
                fontCache: 'global'
            }
        };

        // MathJax 렌더링 함수
        app.config.globalProperties.$renderMath = () => {
            if (window.MathJax) {
                window.MathJax.typesetPromise().then(() => {
                    console.log('MathJax rendering complete');
                }).catch((err) => {
                    console.error('MathJax rendering error: ', err);
                });
            }
        };
    }
};
