<template>
  <div class="gray-background"></div>
  <div class="main-container">
    <div class="login-container">
      <img class="login-logo" src="@/assets/algeomath_logo.png" width="200px">
      <!--div class="on-box">
        <button class="all-button" @click="isOn = true" :class="{ active: isOn === true }">학생</button>
        <button class="all-button" @click="isOn = false" :class="{ active: isOn === false }">선생님</button>
      </div-->
      <div class="change-box">
        <!-- School input -->
        <div class="input-container">
          <label>소속 학교</label>
          <div class="input-icon">
            <input type="text" v-model="user.school" placeholder="ㅇㅇ중학교" />
            <i class="icon-home"></i> <!-- Placeholder for a home icon -->
          </div>
        </div>

        <!-- Grade, Class, Number input -->
        <div class="small-input-container" v-if="isOn">
          <div>
            <label>학년</label>
            <input type="number" v-model="user.grade" placeholder="2" />
          </div>
          <div>
            <label>반</label>
            <input type="number" v-model="user.class" placeholder="1" />
          </div>
          <div>
            <label>번호</label>
            <input type="number" v-model="user.number" placeholder="1" />
          </div>
        </div>
        <!-- Name input -->
        <div class="input-container">
          <label>이름</label>
          <div class="input-icon">
            <input type="text" v-model="user.name" placeholder="홍길동" />
            <i class="icon-user"></i> <!-- Placeholder for a user icon -->
          </div>
        </div>
      </div>
      <div class="text-container">
        <p>ID</p>
        <input type="text" placeholder="아이디" v-model="user.id" maxlength="20" autofocus />
      </div>
      <div class="text-container">
        <p>Password</p>
        <input type="password" placeholder="비밀번호" id="password" v-model="user.password" maxlength="16"
          @blur="passwordValid" @keyup.enter="Signup()" />
        <div class="verify-pw" v-if="!passwordValidFlag">대소문자,숫자가 1개 이상 및 8자 이상 16자 이하</div>
      </div>
      <!-- <div class="line"></div> -->
      <button @click="Signup" type="button" class="signupbtn">
        회원가입
      </button>
      <ul class="goPage">
        <li><a href="https://logins.kofac.re.kr/terms/00/privacyTerms.do">개인정보처리방침 </a></li>
        <li><a href="https://www.algeomath.kr/algeo/contents/join_term/1/view.do">이용약관 </a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'SignupPage',
  data() {
    return {
      user: {
        id: '',
        name: '',
        school: '',
        grade: '',
        class: '',
        number: '',
        password: '',
      },
      passwordValidFlag: true,
      isOn: true,
    }
  },
  props: {
    msg: String
  },
  computed: {
    idValid() {
      return /^[A-Za-z0-9]+$/.test(this.user.id)
    }
  },
  methods: {
    passwordValid() {
      if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,16}$/.test(this.user.password)) {
        this.passwordValidFlag = true
      } else {
        this.passwordValidFlag = false
      }
    },

    Signup: function () {
      if (!/^.+중학교$/.test(this.user.school)) {
        alert('정확히 "ㅇㅇ중학교" 형식으로 입력해주세요.');
        return;
      }
      if (this.user.school == '') {alert('학교를 입력해주세요.'); return;}
      if (this.user.grade == '') {alert('학년을 입력해주세요.'); return;}
      if (this.user.class == '') {alert('반을 입력해주세요.'); return;}
      if (this.user.number == '') {alert('번호를 입력해주세요.'); return;}
      if (this.user.id == '') { alert('아이디를 입력해주세요.'); return; }
      if (this.user.password == '') { alert('비밀번호를 입력해주세요.'); return; }
      if (this.idValid == false) { alert('올바르지 않은 아이디입니다'); return; }
      if (this.passwordValidFlag == false) { alert('올바르지 않은 비밀번호 입니다'); return; }
      axios.post('https://api.aialab.co.kr/users/signup', {
        username: this.user.id,
        name: this.user.name,
        school: this.user.school,
        grade: this.user.grade,
        class_number: this.user.class,
        student_number: this.user.number,
        password: this.user.password
      })
        .then(res => {
          console.log(res);
          if (res.status == 200) {
            alert('가입완료');
            this.$router.push('/LoginPage');
          } else {
            alert('이미 가입된 유저입니다.');
          }
        })
        .catch(error => {
          console.error('Error during signup:', error);
          alert('이미 가입된 유저입니다.');
        });
    },
  }

}
</script>
<style scoped>
.gray-background {
  position: fixed;
  z-index: 1;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #C7D3E5;
}

.main-container {
  display: flex;
  width: 100%;
  height: 98vh;
  margin: auto;
  font-family: 'NanumSquare';
}

.login-container {
  z-index: 2;
  margin: auto;
  padding: 25px 50px;
  width: 450px;
  border-radius: 5%;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-logo {
  width: 150px;
  margin-bottom: 20px;
}

.on-box {
  display: flex;
  margin-bottom: 20px;
  width: 363px;
  height: 31px;
  border: 2px solid #cccccc;
  border-radius: 20px;
}

.change-box {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.all-button {
  background-color: #fbfaf8;
  width: 183px;
  height: 31px;
  border: 0px;
  border-radius: 20px;
  cursor: pointer;
  color: #9f9f9f;
  font-family: 'NanumSquare';
  font-weight: 1000;
}

button.active {
  background-color: #fbfaf8;
  color: #37242c;
  border: 2px solid #37242c;
}

.input-container {
  margin-bottom: 20px;
}

.input-container>label {
  margin: 0 0 10px 0;
}

.input-icon {
  position: relative;
}

.input-icon input {
  width: 80%;
  padding: 10px;
  padding-right: 40px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f0f3fa;
  /* light gray */
  font-size: 16px;
}

.icon-home,
.icon-user {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #888;
}

.small-input-container {
  align-items: center;
  width: 360px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px;
}

.small-input-container div {
  width: 50%;
}

.small-input-container input {
  width: 70%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f0f3fa;
  font-size: 16px;
}


.text-container {
  margin-bottom: 30px;
}

.text-container input[type=text],
.text-container input[type=password] {
  width: 337px;
  height: 36px;
  padding: 17px;
  border-radius: 20px;
  border: 2px solid #cccccc;
  background-color: #ffffff;
  font-size: 12pt;
  box-sizing: border-box;
  font-family: 'NanumSquare';
}

.text-container>.verify-pw {
  font-size: 9pt;
  font-family: 'NanumSquare';
  color: #f60004;
  line-height: 14pt;
}

.line {
  border-top: 1px solid #d8d8d8;
  margin: 50px auto;
}

.loginbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  height: 54px;
  border: 0;
  border-radius: 30px;
  background-color: #5C7FB4;
  font-size: 14pt;
  font-weight: 1000;
  color: #fff;
  cursor: pointer;
  font-family: 'NanumSquare';
}

.signupbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 54px;
  border: 0;
  border-radius: 30px;
  background-color: rgb(92,127,180);
  font-size: 14pt;
  font-weight: 1000;
  color: #fff;
  cursor: pointer;
  font-family: 'NanumSquare';
}

.goPage {
  display: flex;
  padding: 0px;
}

.goPage li:first-child {
  position: relative;
  margin-right: 25px;
}

.goPage li:first-child:after {
  content: '';
  position: absolute;
  right: -15px;
  top: calc(50% - 8px);
  display: block;
  width: 1px;
  height: 16px;
  background: #a3a3a3;
}

.goPage li a {
  font-size: 14px;
  color: #9e917d;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.floating-turtle {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: auto;
  z-index: 1000;
  /* Make sure it appears on top */
  animation: float 6s ease-in-out infinite;
}

.floating-turtle:hover {
  content: url('@/assets/mr_turtle_on.png');
}

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
</style>
