<template>
  <MainTitleBar
    :currentStep="8"
    :total-steps="8"
    :course-title="`삼각형의 외심 탐구하기`"
  />
  <EndPageComponent :lessonId="1" />
</template>

<script>
import EndPageComponent from "@/components/EndPage/EndPageComponent.vue"
import MainTitleBar from "@/components/MainTitleBar.vue"
import store from "@/store";
export default {
  components: {
    MainTitleBar,
    EndPageComponent
  },
  created() {
    store.commit('setLastPath', window.location.pathname);
  },
}
</script>
