import axiosInstance from "@/apis/index";

export const getUser = async () => await axiosInstance.get("/users/me");

export const getRubrics = async (lesson) =>
    await axiosInstance.get("/rubrics", {
        params: {
            lesson,
        },
    });

export const getLessons = async () => await axiosInstance.get("/lessons");

/**
 *
 * @param {Number} stage: stage ID
 * @returns {Object}
 * fetches questions for the given stage
*/
export const getQuestions = async (stage) => await axiosInstance.get('/questions', {
    params : {
        stage
    }
})

/**
 *
 * @param {String} type: lesson type, especially learning_area in dtatbase
 * "대수" or "기하" or "함수"
 * @returns {Array}: array of lessons
 */
export const getLessonsByType = async (type) => {
    try {
        const response = await axiosInstance
            .get("/lessons")
            .then((res) => res.data)

        const lessons = response.filter((lesson) => lesson.learning_area === type);

        return lessons;
    } catch (error) {
        console.log(error);
        return {};
    }
};

/**
 *
 * @param {Number} lessonId: lesson ID
 * @param {Number} position: number of stage postion
 * in order to get the next stage
 * @param {Number} limit: number of stages to fetch
 * @returns {Object}: stage data object
 */
export const getStages = async (lessonId, position, limit = 20) => {
    try {
        const response = await axiosInstance.get("/stages", {
            params: {
                lesson: lessonId,
                skip: 0,
                limit: limit,
            },
        });

        return   response.data.filter((stage) => stage.position === position && stage.lesson_id === lessonId);
    } catch (err) {
        console.log(err);
        return {};
    }
};

export const getStagesAll = async (lessonId, limit = 20) => {
    try {
        return  await axiosInstance.get("/stages", {
            params: {
                lesson: lessonId,
                skip: 0,
                limit: limit,
            },
        });


    } catch (err) {
        console.log(err);
        return {};
    }
};

export const getEvaluationLesson = async ({ sessionId }) => await axiosInstance.get(`/evaluations/lesson?session=${sessionId}`)

export const getEvaluationRubric = async ({ sessionId }) => await axiosInstance.get(`/evaluations/rubric?session=${sessionId}`)

export const getEvaluationSegment = async ({ stageId, sessionId }) => await axiosInstance.get(`/evaluations/segment?session=${sessionId}&stage=${stageId}`)
