<template>
  <div class="title-container">
    <div class="top-container">
      <img class="carrot" alt="carrot" src="@/assets/main_logo.png" @click="Main">
      <div class="text-container" v-if="courseTitle">
        <div class="text-content">{{ courseTitle }}</div>
      </div>
      <div class="progress-container" v-if="showLogoutButton">
        <ProgressBar :totalSteps="totalSteps" :currentStep="currentStep" />
      </div>
    </div>
    <div class="bottom-container" v-if="showLogoutButton && title">
      <img v-if="lessonPhasesImageSrc" :src="lessonPhasesImageSrc" class="title-icon" alt="Title Icon" />
      <h1 class="title2">{{ title }}</h1>
      <div class="student-info" v-if="userData">
        <p>{{ userData.school }} {{ userData.grade }}학년 {{ userData.class_number }}반 {{ userData.student_number }}번</p>
        <p>{{ userData.name }}</p>
      </div>
      <!--button class="logout-button3" @click="Register">로그아웃</button-->
    </div>
    <div class="bottom-line"></div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axiosInstance from "@/apis";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: 'MainTitleBar',
  components: {
    ProgressBar,
  },
  props: {
    title: { // 스테이지별 타이틀
      type: String,
      required: false,
    },
    courseTitle: { // 과목별 타이틀
      type: String,
      required: false,
    },
    lessonPhasesIndex: { // 0은 렌더링 안함, 1은 도입, 2는 탐구, 3는 정리
      type: Number,
      required: false,
    },
    totalSteps: { //전체 스텝
      type: Number,
      required: true,
    },
    currentStep: { //현재 스텝
      type: Number,
      required: true,
    },
    nextPage: { //다음 페이지
      type: String,
      required: true,
    }
  },
  data() {
    return {
      userType: '', // Initialize with empty string
      userData: "",
    };
  },
  mounted() {
    if (this.$route.path !== '/LoginPage') {
      if (!Cookies.get('login_cookie')) {
        //this.$router.push('/LoginPage');
        //alert('다시 로그인해주세요.');
      } else {
        this.fetchUserData();
      }
    }
  },
  beforeUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (this.timeIntervalId) {
      clearInterval(this.timeIntervalId);
    }
  },
  methods: {
    async fetchUserData() {
      try {
        const response = await axiosInstance.get('/users/me');
        console.log(response);
        this.userData = response.data;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    Main() {
      window.location.reload();
    },
    Register() {
      Cookies.set('login_cookie', '');
      this.$router.push('/LoginPage');
      alert('로그아웃 되었습니다.');
    },
  },
  computed: {
    showLogoutButton() {
      // 로그아웃 버튼을 표시할지 여부를 반환, /LoginPage 경로에서는 false를 반환
      return this.$route.path !== '/LoginPage';
    },
    lessonPhasesImageSrc() { // lessonPhasesIndex 프롭을 사용하여 이미지를 설정
      if (this.lessonPhasesIndex === 0) { // lessonPhasesIndex가 0인 경우 null 반환하여 이미지를 렌더링하지 않음
        return null;
      } else {
        const lessonPhasesImages = [
          [],
          require('@/assets/title1.png'), //도입
          require('@/assets/title2.png'), //탐구
          require('@/assets/title3.png')  //정리
        ];
        return lessonPhasesImages[this.lessonPhasesIndex] || lessonPhasesImages[1]; // v-vind 프롭을 입력안하면 기본으로 도입 표시함.
      }
    }
  }
}
</script>
<style scoped>
.title-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  z-index: 2;
}

.top-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background: #5C7FB4;
}

.carrot {
  margin: 12px 0 10px 50px;
  width: 135px;
  height: 30px;
  cursor: pointer;
}

.title2 {
  flex-grow: 1;
  color: #000;
  font-family: 'NanumSquare', sans-serif;
  font-size: 22px;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 10px;
}

.progress-container {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  margin: 15px 30px;
}

.progress-bar {
  position: absolute;
  right: 50px;
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  align-items: center;
}

.student-info {
  font-family: 'NanumSquare';
  font-weight: 700;
  color: #4A4A4A;
  text-align: right;
  margin: 3px 55px;
}

.student-info p {
  margin: 0;
  font-size: 16px;
}

.logout-button3 {
  font-family: 'NanumSquare';
  font-weight: 1000;
  margin: 7px;
  width: 126px;
  height: 38px;
  color: #bbbbbb;
  background-color: #5C7FB4;
  border: 1px solid #bbbbbb;
  border-radius: 20px;
  cursor: pointer;
}

.logout-button3:hover {
  color: #5C7FB4;
  background-color: #bbbbbb;
}

.red {
  color: red;
  font-size: 12pt;
  font-weight: 500;
  margin-left: 10px;
}

.text-container {
  display: inline-flex;
  height: auto;
  background: #3F5B86;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding: 2px 24px 4px;
  margin: 10px 0;
  margin-left: 30px;
}

.text-content {
  text-align: center;
  color: #E5D8A3;
  font-size: 15px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.bottom-line {
  height: 2px;
  background-color: #ccc;
  width: 94%;
  margin: 0 auto;
}

.title-icon {
  width: 55px;
  height: 30px;
  margin-left: 50px;
  transform: scale(0.85);
}

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
</style>
