<template>
  <MainTitleBar
    :currentStep="12"
    :total-steps="12"
    :course-title="`일차함수의 그래프의 성질 탐구하기`"
  />
  <EndPageComponent :lessonId="3" />
  </template>
  
  <script>
  import EndPageComponent from "@/components/EndPage/EndPageComponent.vue"
  import MainTitleBar from "@/components/MainTitleBar.vue"
  import store from "@/store";
  export default {
    components: {
      MainTitleBar,
      EndPageComponent
    },
    created() {
      store.commit('setLastPath', window.location.pathname);
    },
  }
  </script>